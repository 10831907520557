import axios from 'axios';

const { REACT_APP_BNE_ENDPOINT, REACT_APP_TOKEN} = process.env
const token = localStorage.getItem(`${REACT_APP_TOKEN}`);

const endpoint = axios.create({
  baseURL: REACT_APP_BNE_ENDPOINT,
  headers: {
    authorization: token,
    app_version: process.env.REACT_APP_VERSION,
  },
});

// Add a request interceptor
endpoint.interceptors.request.use(
  function (config) {

    if(config.method === 'post'){
      config.headers['Content-Type'] = 'application/json';
    }
    // TODO: fetch user from localStorage
    // const now = new Date().getTime();
    // const { user, signOut } = useAuth();
    // if (!!user && now >= data.user.expiration) {
    //   signOut();
    //   throw new Error('Access expired');
    // }

    // const user = localStorage.getItem(`${process.env.REACT_APP_USER}`);
    // if (user) {
    //   // console.log(user);
    //   const parsed = JSON.parse(user);
    //   if (now >= parsed.expiration) {
    //     console.log('expired');
    //   }
    // }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
endpoint.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
    // return response;
  },
  function (error) {
    console.log(error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

export default endpoint;
