import React from 'react';

import { useAuth } from 'hooks';

import { Navigate, useLocation } from 'react-router-dom';

function PrivateRoute({ outlet, adminRoute = false }) {
  const { pathname } = useLocation();
  const { user } = useAuth();

  const authenticationPath = '/authentication';

  if (!user && ![pathname].includes(authenticationPath) || (adminRoute && !!user && !user.isAdmin)) {
    return <Navigate to={{ pathname: '/' }} replace />;
  }
  if(!!user && !user.steam){
    return <Navigate to={{ pathname: '/matches' }} replace />;
  }
  // if (!!user && [authenticationPath].includes(pathname)) {
  //   return <Navigate to={{ pathname: basePath }} replace />;
  // }

  // if (
  //   !user &&
  //   [authenticationPath, forgotPasswordPath, ADMauthenticationPath].indexOf(
  //     pathname,
  //   ) < 0 &&
  //   !pathname.includes(resetPasswordPath)
  // ) {
  //   // navigate(authenticationPath, { replace: true });
  //   return <Navigate to={{ pathname: authenticationPath }} replace />;
  // }
  // if (!!user && pathname === '/signin') {
  //   // navigate(basePath, { replace: true });
  //   return <Navigate to={{ pathname: basePath }} replace />;
  // }
  // if (!!user && !!user.passchange && pathname !== firstAccessPath) {
  //   // navigate(firstAccessPath, { replace: true });
  //   return <Navigate to={{ pathname: firstAccessPath }} replace />;
  // }
  // if (adminOnly && !!user && !user.adm) {
  //   // navigate(basePath, { replace: true });
  //   return <Navigate to={{ pathname: basePath }} replace />;
  // }
  return outlet;
}

export default PrivateRoute;
