import global from '../../assets/global.png';
import gold from '../../assets/gold.png';
import guardian from '../../assets/guardian.png';
import legendary from '../../assets/legendary.png';
import pink from '../../assets/pink.png';
import silver from '../../assets/silver.png';
import supreme from '../../assets/supreme.png';

const rankMaps = new Map();

rankMaps.set('global',global);
rankMaps.set('gold',gold);
rankMaps.set('guardian',guardian);
rankMaps.set('legendary',legendary);
rankMaps.set('pink',pink);
rankMaps.set('silver',silver);
rankMaps.set('supreme',supreme);

export {rankMaps};
