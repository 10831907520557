import React, { useState, useCallback, useEffect } from 'react';

import { Container } from 'components';

import { useNavigate } from 'react-router-dom';
import { maps_assets } from 'assets/map';

import { useMonth, useLoading } from 'hooks';

import api from 'service';



const matches_stats_key = process.env.REACT_APP_MATCHES;

import './styles.css';
import { FaTimes } from 'react-icons/fa';

export default function Matches() {
  const { toggleLoading } = useLoading();
  const { season } = useMonth();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  // const [currentPage, setCurrentPage] = useState(1);

  const [matches, setMatches] = useState(() => {
    const localStats = localStorage.getItem(matches_stats_key);
    if (localStats) {
      return JSON.parse(localStats).map((item) => {
        if(!item.players) {
          item.players = [];
        }
        return item;
      });
    }
    return [];
  });
  const [originalMatches, setOriginalMatches] = useState(() => {
    const localStats = localStorage.getItem(matches_stats_key);
    if (localStats) {
      return JSON.parse(localStats).map((item) => {
        if(!item.players) {
          item.players = [];
        }
        return item;
      });
    }
    return [];
  });

  const getMatches = useCallback(async () => {
    toggleLoading();
    try {
      const storedMatches = localStorage.getItem(matches_stats_key);
      const response = await api.get(`/system/matches.php`);
      if (
        !storedMatches ||
        (!!storedMatches && JSON.stringify(response) !== storedMatches)
      ) {
        localStorage.setItem(matches_stats_key, JSON.stringify(response));
        setOriginalMatches(response);
        setMatches(response);
      } else {
        // console.log('matches_stats -- using cached data');
      }
    } catch (e) {
      console.error(e);
    } finally {
      toggleLoading();
    }

  }, []);

  useEffect(() => {
    getMatches();
  }, []);

  const handleNavigate = useCallback((ev) => {
    const { uuid } = ev.currentTarget.dataset;
    navigate(`/matches/${uuid}`, {});
  }, []);

  useEffect(() => {
    if(!search){
      setMatches(originalMatches);
      return;
    }

    const lowered = search.toLowerCase();
    if(isNaN(search)){
      const baseSearch = [
        ...originalMatches.map((item) => ({
          ...item,
          t1name: item.team1.name.toLowerCase(),
          t2name: item.team2.name.toLowerCase(),
          players: item.players.map((p) => p.name.toLowerCase())
        })
      )];

      setMatches(baseSearch.filter((item) => {
        return ((item.t1name.includes(lowered) || item.t1name.includes(lowered)) || item.players.some((p) => p.includes(lowered)));
      }))
      // setMatches(baseSearch.filter((item) => (item.t1name.includes(lowered) || item.t2name.includes(lowered))))
    } else {
      setMatches(originalMatches.filter((item) => (item.matchid).includes(search)))
    }
  }, [search])

  return (
    <Container>
      <div className='container'>
        <div className="mt-10 mb-5 text-lg md:text-2xl text-white w-full md:w-4/6 mx-auto text-center">BNE Premier | Season {season} - {new Date().getFullYear()}</div>
        <div className='flex flex-col justify-center items-center gap-x-3'>
          <span className="text-sky-300">Pesquise por partida, capitão ou jogador: </span>
          <div className='flex flex-row items-center gap-x-1 bg-white/15 rounded-sm w-52 px-2 py-1 border border-sky-400'>
            <input className='bg-transparent w-full outline-none caret-sky-400 text-sky-200' onChange={(ev) => setSearch(ev.currentTarget.value)} value={search}></input>
            {search.length > 0 && (
              <button type="button" className='text-sky-400' onClick={() => setSearch('')}><FaTimes /></button>
            )}
          </div>
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 mt-4'>
          {matches.map((item) => (
            <button key={item.uuid} type='button' className='button-container' onClick={handleNavigate} data-uuid={item.uuid}>
              <div className='div-container'>
                <div>
                  <div className='flex flex-row gap-x-1 justify-start'>
                    <strong>{item.team1.name}</strong>
                    <span>vs</span>
                    <strong>{item.team2.name}</strong>
                  </div>
                  <div className={`flex flex-row gap-x-3 text-start w-48 mt-4 ${item.team1.won ? 'text-emerald-400' : 'text-red-400'}`}>
                    <span>{item.team1.name}</span>
                    <strong>{item.team1.score}</strong>
                  </div>
                  <div className={` flex flex-row gap-x-3 text-start w-48 ${item.team2.won ? 'text-emerald-400' : 'text-red-400'}`}>
                    <span>{item.team2.name}</span>
                    <strong>{item.team2.score}</strong>
                  </div>
                  <div className='mt-2 text-xs text-start'>
                    #{item.matchid}
                  </div>
                </div>
                <div className='flex flex-row justify-end items-start'>
                  <img src={maps_assets[item.map.id].asset} className="w-14 my-0" />
                </div>
              </div>
            </button>
          ))}
        </div>
      </div>
      {/* <Pagination data={matches} maximumItems={20}></Pagination> */}
    </Container>
  );
}
