const localStorageItems = [
  `${process.env.REACT_APP_TOKEN}`,
  `${process.env.REACT_APP_USER}`,
  `${process.env.REACT_APP_CURRENT_RANKING}`,
  `${process.env.REACT_APP_USER_SEASON_STATS}`,
  `${process.env.REACT_APP_MATCHES}`,
  `${process.env.REACT_APP_USER_MATCHES}`,
  `${process.env.REACT_APP_SHOP_CATEGORIES}`,
  `${process.env.REACT_APP_SHOP_WEAPONS}`,
  `${process.env.REACT_APP_CURRENT_RANKING_SNAPSHOT}`
];

export default localStorageItems;
