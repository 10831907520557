import React, { useEffect, useCallback, useState, useMemo } from 'react';

import { useLocation } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight, FaDotCircle } from 'react-icons/fa';
import { FaCircleChevronLeft, FaCircleChevronRight } from 'react-icons/fa6';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import { maps_assets } from 'assets/map';

import { useLoading } from 'hooks';

// eslint-disable-next-line no-unused-vars
export default function Pagination({ data = [], maximumItems = 10, maximumVisiblePages = 5, }) {
  const { loading } = useLoading();
  const { pathname } = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [records, setRecords] = useState([]);

  const pagesAmount = useMemo(() => {
    return Math.ceil(data.length / maximumItems);
  }, [data, maximumItems]);

  const [pages] = useState(() => {
    let pagesArray = [];
    // console.log(pagesAmount);
    for (let i = 1; i <= pagesAmount; i++) {
      pagesArray.push(i);
    }
    return pagesArray;
  });

  useEffect(() => {
    setRecords(data.slice(maximumItems * (currentPage - 1), currentPage * maximumItems));
  }, [data, currentPage, maximumItems]);

  // useEffect(() => {
  //   let visiblePages = maximumVisiblePages;

  //   let pagesArray = [];

  //   const startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
  //   const endPage = Math.min(pagesAmount, startPage + visiblePages - 1);

  //   for (let i = startPage; i <= endPage; i++) {
  //     pagesArray.push(i);
  //   }

  //   setPages(pagesArray);
  // }, [currentPage, pagesAmount, maximumVisiblePages]);

  const handlePage = useCallback((ev) => {
    const { page } = ev.currentTarget.dataset;

    setCurrentPage(parseInt(page, 10));
  }, []);

  const handleNavigateMatch = useCallback((ev) => {
    const { match } = ev.currentTarget.dataset;
    window.open(`${pathname.replace('/home', '/matches')}/${match}`);
  }, []);

  return loading ? (
    <div className="flex flex-row justify-center items-center gap-x-3 mt-12">
      <span className="italic text-sky-200 ">Aguarde, carregando partidas</span>
      <span className="animate-spin text-sky-200">
        <AiOutlineLoading3Quarters className="h-5 w-5 " />
      </span>
    </div>
  ) : (
    <div className="select-none ">
      <div className="bg-zinc-800 rounded-xl w-full md:w-4/6 mx-auto overflow-hidden py-2">
        <table className="w-full">
          <thead className="bg-zinc-800 text-sky-200 text-center border-b border-sky-700 text-lg">
            <tr>
            {/* <td></td> */}
            <td className="pl-7">Mapa</td>
            <td>Kills</td>
            <td>Deaths</td>
            <td className='hidden md:table-cell'>KDR</td>
            <td>ADR</td>
            <td className='hidden md:table-cell'>MVP</td>
            <td className='hidden md:table-cell'>Points</td>
            </tr>
          </thead>
          <tbody className="">
            {records.map((item) => (
              <tr key={item.uuid} data-match={item.uuid} onClick={handleNavigateMatch} className="text-center text-zinc-300 text-base even:bg-zinc-800 odd:bg-zinc-700/25 hover:bg-zinc-600/25 cursor-pointer pointer-events-auto">
                {/*  even:bg-zinc-400/25 odd:bg-sky-700/15 hover:bg-sky-400/25 cursor-pointer pointer-events-auto */}
                {/* <td className="px-2">

                </td> */}
                <td className="h-12">
                  <div className="flex flex-row items-center justify-center gap-x-4">
                    <FaDotCircle className={item.won ? 'text-emerald-400' : 'text-red-500'} />
                    <img src={maps_assets[item.map.id].asset} className="h-8 my-0" />
                  </div>
                  {/* <img src={maps_assets[item.map.id].asset} className="h-8 mx-auto my-0" /> */}
                </td>
                <td>{item.kills}</td>
                <td>{item.deaths}</td>
                <td className='hidden md:table-cell'>{parseFloat((item.kills / item.deaths).toFixed(2))}</td>
                <td>{item.adr}</td>
                <td className='hidden md:table-cell'>{item.mvp}</td>
                <td className='hidden md:table-cell'>{item.totalpoints}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="w-full md:w-4/6 mx-auto flex items-center justify-between  bg-transparent px-4 py-3 sm:px-6">
        {' '}
        {/* border-t border-sky-200 */}
        <div className="flex flex-1 justify-between md:hidden text-sm">
          <button data-action="prev" data-page={currentPage - 1} className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 hover:text-white focus:outline-offset-0 gap-x-2 ${currentPage === 1 ? 'opacity-30 pointer-events-none' : 'opacity-1'}`} onClick={handlePage}>
            <FaCircleChevronLeft/> Anterior
          </button>
          <button data-action="next" data-page={currentPage + 1} className={`relative inline-flex items-center rounded-md px-2 py-2 text-gray-400  hover:text-white focus:outline-offset-0 gap-x-2 ${currentPage === pagesAmount ? 'opacity-30 pointer-events-none' : 'opacity-1'}`} onClick={handlePage}>
            Próximo <FaCircleChevronRight/>
          </button>
        </div>
        <div className="hidden md:flex md:flex-1 md:items-center md:justify-between">
          <div>
            <p className="text-sm text-sky-300">
              <span className="font-medium">{maximumItems * (currentPage === pagesAmount ? currentPage - 1 : currentPage) + (currentPage === pagesAmount ? records.length : 0)}</span> de <span className="font-medium">{data.length}</span>
            </p>
          </div>
          <div>
            <nav aria-label="Pagination" className="isolate inline-flex -space-x-px rounded-md shadow-sm text-sm gap-x-2">
              <button data-action="prev" data-page={currentPage - 1} className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 hover:text-white focus:outline-offset-0 ${currentPage === 1 ? 'opacity-30 pointer-events-none' : 'opacity-1'}`} onClick={handlePage}>
                <FaChevronLeft />
              </button>

              {pages.map((page) => (
                <button
                  key={page}
                  data-action="direct"
                  data-page={page}
                  className={`relative z-10 inline-flex items-center px-4 py-2 font-semibold rounded-md  hover:text-sky-300 ${page === currentPage ? 'text-sky-300 border-sky-950 bg-sky-800/20 hover:bg-sky-800/40' : 'text-white hover:border-sky-200 hover:bg-sky-300/20'}`}
                  onClick={handlePage}
                >
                  {page}
                </button>
              ))}

              <button data-action="next" data-page={currentPage + 1} className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400  hover:text-white focus:outline-offset-0 ${currentPage === pagesAmount ? 'opacity-30 pointer-events-none' : 'opacity-1'}`} onClick={handlePage}>
                <FaChevronRight />
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
