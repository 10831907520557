import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Container, DownloadComponentV2 } from 'components';

import { useLoading, useDownload, useAuth } from 'hooks';

import { useLocation } from 'react-router-dom';
import { maps_assets } from 'assets/map';

import { TfiTarget } from 'react-icons/tfi';
import { FaHandshake, FaExplosion, FaStar } from 'react-icons/fa6';


import api from 'service';

export default function Match() {
  const { user } = useAuth();
  const { toggleLoading } = useLoading();
  const { refreshPreActionStatus } = useDownload();
  const { pathname } = useLocation();
  const [matchUUID] = useState(() => {
    return pathname.replace('/matches/', '');
  });

  const [info, setInfo] = useState({});
  const [squadA, setSquadA] = useState({});
  const [squadB, setSquadB] = useState({});

  const getMatch = useCallback(async () => {
    toggleLoading();
    try {
      const response = await api.get(`/system/match.php?id=${matchUUID}`);
      console.log(response)
      const { matchInfo, squadA: sA, squadB: sB } = response;
      setInfo(matchInfo);
      sA.players.sort((a, b) => a.adr < b.adr ? 1 : (b.adr < a.adr ? -1 : 0))
      sB.players.sort((a, b) => a.adr < b.adr ? 1 : (b.adr < a.adr ? -1 : 0))
      setSquadA(sA);
      setSquadB(sB);
      // document.title = `[ Premier BNE - Match #${matchInfo.matchnum} ]`;
    } catch (e) {
      console.error(e);
    } finally {
      toggleLoading();
    }
  }, [matchUUID]);

  useEffect(() => {
    getMatch();
  }, [getMatch]);

  const hasMatch = useMemo(() => {
    return Object.keys(info).length > 0;
  }, [info]);

  const bestPlayers = useMemo(() => {
    if (!!squadA.players && !!squadB.players) {
      let all = [...squadA.players, ...squadB.players];
      // console.log(all);

      return all.reduce(
        (prev, player) => {
          return {
            kills: prev.kills === null || player.kills > prev.kills.kills ? player : prev.kills,
            assists: prev.assists === null || player.assists > prev.assists.assists ? player : prev.assists,
            adr: prev.adr === null || player.adr > prev.adr.adr ? player : prev.adr,
            mvp: prev.mvp === null || player.mvp > prev.mvp.mvp ? player : prev.mvp,
          };
        },
        { kills: null, assists: null, adr: null, mvp: null },
      );
    }
    return {};
  }, [squadA, squadB]);

  const callDEMDownload = useCallback(
    async (ev) => {
      const { match, server } = ev.detail;

      await api.get(`/external/fileUpload.php?desiredMatch=${match}&serverID=${server}`).then(() => {});
      refreshPreActionStatus(true);
      // handleDownload({ filename: match, extension: 'dem' });
    },
    [refreshPreActionStatus],
  );

  useEffect(() => {
    document.addEventListener('downloadPreAction', callDEMDownload);
    return () => {
      document.removeEventListener('downloadPreAction', callDEMDownload);
    };
  }, []);

  // useEffect(() => {
  //   // console.log(bestPlayers);
  //   if (hasMatch) {
  //     const filename = `${format(info.start_time, 'yyyy_MM_dd_HH_mm')}_${info.map_name.replace('de_', '')}`;

  //     const downloadEvent = new CustomEvent('downloadConfig', {
  //       detail: {
  //         file: { filename, extension: 'dem' },
  //         preaction: 'downloadPreAction',
  //         eventDetails: { match: filename,  server: info.server},
  //       },
  //     });

  //     if (info.downloadable) {
  //       downloadConfig(downloadEvent);
  //     }
  //   }
  // }, [info, hasMatch]);

  return (
    <Container>
      {hasMatch ? (
        <div className="relative">
          <img src={maps_assets[info.map_name].asset} className="opacity-5 absolute h-full left-1/2 -translate-x-1/2 pointer-events-none" />
          <div id="donwloadContainer">
            <div className="mt-4 mr-10 w-full flex flex-row px-14 items-end justify-end text-white">
              <div className="">{user && user.isPremium && info.downloadable ? <DownloadComponentV2 uuid={matchUUID} /> : null}</div>
            </div>
          </div>
          <div className="container">
            <div className="grid grid-cols-2 sm:grid-cols-4 mx-auto my-10 text-white gap-2">

              <div className="col-span-2 sm:col-span-4 flex flex-row items-center justify-center gap-x-1 px-2"><span className='text-sky-200'>Partida</span>{info.matchnum}</div>
              <div className="flex flex-col items-center justify-center">
                <div className="flex flex-row items-center gap-x-3 text-sky-200">
                  <TfiTarget />
                  <span>Most kills</span>
                </div>
                <div>
                  {bestPlayers.kills.playername}
                  <span className="text-zinc-400">&nbsp;({bestPlayers.kills.kills})</span>
                </div>
              </div>

              <div className="flex flex-col items-center justify-center">
                <div className="flex flex-row items-center gap-x-3 text-sky-200">
                  <FaHandshake />
                  <span>Most assists</span>
                </div>
                <div>
                  {bestPlayers.assists.playername}
                  <span className="text-zinc-400">&nbsp;({bestPlayers.assists.assists})</span>
                </div>
              </div>

              <div className="flex flex-col items-center justify-center">
                <div className="flex flex-row items-center gap-x-3 text-sky-200">
                  <FaExplosion />
                  <span>Greatest ADR</span>
                </div>
                <div>
                  {bestPlayers.adr.playername}
                  <span className="text-zinc-400">&nbsp;({bestPlayers.adr.adr})</span>
                </div>
              </div>

              <div className="flex flex-col items-center justify-center">
                <div className="flex flex-row items-center gap-x-3 text-sky-200">
                  <FaStar />
                  <span>Most MVPS</span>
                </div>
                <div>
                  {bestPlayers.mvp.playername}
                  <span className="text-zinc-400">&nbsp;({bestPlayers.mvp.mvp})</span>
                </div>
              </div>
            </div>

            {[squadA, squadB].map((squad, index) => {
              const classes = {
                squad: {
                  name: squad.squad.winner ? `text-3xl text-emerald-400` : `text-3xl text-red-400`,
                  score: squad.squad.winner ? `text-emerald-400 bg-emerald-700/25 border-emerald-500` : `text-red-400 bg-red-800/25 border-red-500`,
                },
                tableHeaders: {
                  bg: squad.squad.winner ? 'bg-emerald-800/25': 'bg-red-800/25',
                  divider: squad.squad.winner ? 'divide-emerald-700' : 'divide-red-700',
                }
              }
              return ((
                <div className={` mx-auto w-full lg:w-2/3 rounded-md ${index > 0 ? 'mt-5' : ''}`} key={squad.squad.name}>
                  <div id="scoreContainer" className="flex items-center gap-x-4 my-2">
                    <div className={`flex text-2xl w-12 h-12 border rounded-md  font-bold items-center justify-center ${classes.squad.score}`}>{squad.squad.score}</div>
                    <div className={classes.squad.name}>{squad.squad.name}</div>
                  </div>

                  <table className={`${classes.tableHeaders.divider} w-full text-stone-300  divide-y  `}>
                    <thead>
                      <tr className={classes.tableHeaders.bg}>
                        <th className='min-w-30 max-w-30 w-30 text-left'></th>
                        <th className='w-20'>Kills</th>
                        <th className='w-20'>Deaths</th>
                        <th className='w-20'>Assists</th>
                        <th className='w-20'>ADR</th>
                        <th className='hidden sm:table-cell w-20'>KDR</th>
                        <th className='hidden sm:table-cell w-20'>MVP</th>
                      </tr>
                    </thead>
                    <tbody>
                      {squad.players.map((player) => (
                        <tr key={player.steamid64} className='text-xs md:text-base odd:bg-stone-800/50 even:bg-stone-800/75 hover:bg-stone-600 duration-300'>
                          <td className='px-2'>
                            <div className='flex flex-row items-center justify-items-center gap-x-2'>
                              <img alt="" src={player.avatar} className="h-10 w-10 flex-none rounded-full bg-zinc-800" />
                              <p>{player.playername}</p>
                            </div>
                          </td>
                          <td className='text-center'>{player.kills}</td>
                          <td className='text-center'>{player.deaths}</td>
                          <td className='text-center'>{player.assists}</td>
                          <td className='text-center'>{player.adr}</td>
                          <td className='hidden sm:table-cell text-center'>{player.kdr}</td>
                          <td className='hidden sm:table-cell text-center'>{player.mvp}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))
            })}

            {/* <div className="grid grid-cols-2 mx-auto mt-5" style={{ maxWidth: '1080px' }}>
              <div id="squadA" className="">
                <SquadComponent base={squadA} />
              </div>
              <div id="squadB" className="">
                <SquadComponent base={squadB} />
              </div>
            </div> */}
          </div>
        </div>
      ) : null}
    </Container>
  );
}

// function SquadComponent({ base }) {
//   const baseclass = {
//     squadName: base.squad.winner ? `text-3xl text-emerald-400` : `text-3xl text-red-400`,
//     score: `flex text-2xl w-12 h-12 border rounded-md  font-bold items-center justify-center ${base.squad.winner ? `text-emerald-400 bg-emerald-700/25 border-emerald-500` : `text-red-400 bg-red-800/25 border-red-500`}`,
//   };

//   return (
//     <div className="flex flex-col items-center">
//       <div id="scoreContainer" className="flex items-center gap-x-4">
//         <div className={baseclass.squadName}>{base.squad.name}</div>
//         <div className={baseclass.score}>{base.squad.score}</div>
//       </div>
//       <div id="playersContainer">
//         <ul role="list" className="divide-y divide-zinc-500">
//           {base.players.map((player) => (
//             <li key={player.steamid64} className="flex gap-x-6 pt-3 pb-2">
//               <div className="flex flex-col items-center justify-center w-full">
//                 <div className="flex w-full gap-x-4 items-center mb-1 px-3">
//                   <img alt="" src={player.avatar} className="h-10 w-10 flex-none rounded-full bg-zinc-800" />
//                   <div className="min-w-0 flex-auto">
//                     <p className="text-3xl font-semibold leading-6 text-gray-300">{player.playername}</p>
//                   </div>
//                 </div>
//                 <div className="grid grid-cols-6 text-white gap-x-4 px-1 mt-2">
//                   <div className="text-center">Kills</div>
//                   <div className="text-center">Deaths</div>
//                   <div className="text-center">Assists</div>
//                   <div className="text-center">KDR</div>
//                   <div className="text-center">MVP</div>
//                   <div className="text-center">ADR</div>
//                   <div className="text-center">{player.kills}</div>
//                   <div className="text-center">{player.deaths}</div>
//                   <div className="text-center">{player.assists}</div>
//                   <div className="text-center">{player.kdr}</div>
//                   <div className="text-center">{player.mvp}</div>
//                   <div className="text-center">{player.adr}</div>
//                 </div>
//               </div>
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// }
