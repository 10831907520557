import React, { useCallback, createContext, useState, useEffect, useContext } from 'react';

import api from 'service';

import { useAuth, useLoading } from './';

const SkinsHook = createContext({});

import { mappedContent, mappedAgents, mappedGloves, mappedKnives, mappedMusicKits, mappedWeapons, Squad, Mode, Tier, mappedSquad, mappedMode } from '../utils/skins/skins_helper';

function SkinsProvider({children}) {
  const { user } = useAuth();
  const { loading, toggleLoading } = useLoading();

  useEffect(() => {
  }, []);

  const getWeaponCategories = useCallback(() => {
    return Array.from(mappedContent.keys()).filter((item) => ![ "knife", "glove", "agents", "music"].includes(item));
  })

  const getContent = useCallback((which) => {
    if(["knife", "glove", "agents", "music"].includes(which)){
      return mappedContent.get(which);
    } else {
      if(!mappedContent.get(which) || !mappedContent.get(which).content){
        return null;
      }

      const begin = {...mappedContent.get(which)};
      begin.content = begin.content.map((weapon) => {
        let currentWeapon = mappedWeapons.get(weapon.value);
        return {...weapon, team: currentWeapon.team}
      })
      return begin;
    }



    // return mappedContent.get(which);
  }, [])
  const getAgents = useCallback((which) => {
    return mappedAgents.get(which);
  }, []);
  const getGloves = useCallback((which) => {
    return mappedGloves.get(which)
  }, []);

  const getKnives = useCallback((which) => {
    return mappedKnives.get(which)
  }, []);
  const getWeapons = useCallback((which) => {
    return mappedWeapons.get(which)
  }, []);
  const getMusicKits = useCallback(() => {
    return mappedMusicKits;
  }, []);

  return (
    <SkinsHook.Provider
      value={{
        getWeaponCategories,
        getContent,
        getAgents,
        getWeapons,
        getMusicKits,
        getGloves,
        getKnives
      }}
    >
      {children}
    </SkinsHook.Provider>
  );
}

function useSkins() {
  const context = useContext(SkinsHook);

  if (!context) {
    throw new Error('useShop must be used within and ShopProvider');
  }

  return context;
}



export { SkinsProvider, useSkins, Squad, Mode, Tier, mappedMode, mappedSquad };
