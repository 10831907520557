import jsonAgents from './agents.json';
import jsonGloves from './gloves.json';
import jsonMusic from './music.json';
import jsonSkins from './skins.json';

const agentsJSON = jsonAgents.filter((item) => item.model !== 'null');
const glovesJSON = jsonGloves.filter((item) => item.paint !== '0');
const skinsJSON = jsonSkins.filter((item) => parseInt(item.paint, 10) != '0');
const musicJSON = jsonMusic;

export const Tier = {
  COMMON: 'common',
  T1: 'tier1',
  T2: 'tier2',
  T3: 'tier3'
}

export const Squad = {
  CT: 'CT',
  T: 'T',
  NONE: '',
}

export const Mode = {
  Agent: 'agent',
  Music: 'music_kit',
  Glove: 'glove',
  Knife: 'knife',
  Skins: 'skin',
  NONE: '',
}



const dictionaryWeaponCategory = new Map();
dictionaryWeaponCategory.set('weapon_usp_silencer', 'pistol');
dictionaryWeaponCategory.set('weapon_deagle', 'pistol');
dictionaryWeaponCategory.set('weapon_elite', 'pistol');
dictionaryWeaponCategory.set('weapon_fiveseven', 'pistol');
dictionaryWeaponCategory.set('weapon_glock', 'pistol');
dictionaryWeaponCategory.set('weapon_tec9', 'pistol');
dictionaryWeaponCategory.set('weapon_taser', 'pistol');
dictionaryWeaponCategory.set('weapon_hkp2000', 'pistol');
dictionaryWeaponCategory.set('weapon_cz75a', 'pistol');
dictionaryWeaponCategory.set('weapon_revolver', 'pistol');
dictionaryWeaponCategory.set('weapon_p250', 'pistol');
// --
dictionaryWeaponCategory.set('weapon_ak47', 'rifle');
dictionaryWeaponCategory.set('weapon_aug', 'rifle');
dictionaryWeaponCategory.set('weapon_famas', 'rifle');
dictionaryWeaponCategory.set('weapon_galilar', 'rifle');
dictionaryWeaponCategory.set('weapon_sg556', 'rifle');
dictionaryWeaponCategory.set('weapon_m4a1_silencer', 'rifle');
dictionaryWeaponCategory.set('weapon_m4a1', 'rifle');
// --
dictionaryWeaponCategory.set('weapon_awp', 'sniper');
dictionaryWeaponCategory.set('weapon_g3sg1', 'sniper');
dictionaryWeaponCategory.set('weapon_scar20', 'sniper');
dictionaryWeaponCategory.set('weapon_ssg08', 'sniper');
// --
dictionaryWeaponCategory.set('weapon_m249', 'heavy');
dictionaryWeaponCategory.set('weapon_negev', 'heavy');
// --
dictionaryWeaponCategory.set('weapon_mac10', 'smg');
dictionaryWeaponCategory.set('weapon_p90', 'smg');
dictionaryWeaponCategory.set('weapon_mp5sd', 'smg');
dictionaryWeaponCategory.set('weapon_ump45', 'smg');
dictionaryWeaponCategory.set('weapon_bizon', 'smg');
dictionaryWeaponCategory.set('weapon_mp7', 'smg');
dictionaryWeaponCategory.set('weapon_mp9', 'smg');
// --
dictionaryWeaponCategory.set('weapon_xm1014', 'shotgun');
dictionaryWeaponCategory.set('weapon_mag7', 'shotgun');
dictionaryWeaponCategory.set('weapon_sawedoff', 'shotgun');
dictionaryWeaponCategory.set('weapon_nova', 'shotgun');
// --
dictionaryWeaponCategory.set('weapon_bayonet', 'knife');
dictionaryWeaponCategory.set('weapon_knife_css', 'knife');
dictionaryWeaponCategory.set('weapon_knife_flip', 'knife');
dictionaryWeaponCategory.set('weapon_knife_gut', 'knife');
dictionaryWeaponCategory.set('weapon_knife_karambit', 'knife');
dictionaryWeaponCategory.set('weapon_knife_m9_bayonet', 'knife');
dictionaryWeaponCategory.set('weapon_knife_tactical', 'knife');
dictionaryWeaponCategory.set('weapon_knife_falchion', 'knife');
dictionaryWeaponCategory.set('weapon_knife_survival_bowie', 'knife');
dictionaryWeaponCategory.set('weapon_knife_butterfly', 'knife');
dictionaryWeaponCategory.set('weapon_knife_push', 'knife');
dictionaryWeaponCategory.set('weapon_knife_cord', 'knife');
dictionaryWeaponCategory.set('weapon_knife_canis', 'knife');
dictionaryWeaponCategory.set('weapon_knife_ursus', 'knife');
dictionaryWeaponCategory.set('weapon_knife_gypsy_jackknife', 'knife');
dictionaryWeaponCategory.set('weapon_knife_outdoor', 'knife');
dictionaryWeaponCategory.set('weapon_knife_stiletto', 'knife');
dictionaryWeaponCategory.set('weapon_knife_widowmaker', 'knife');
dictionaryWeaponCategory.set('weapon_knife_skeleton', 'knife');
dictionaryWeaponCategory.set('weapon_knife_kukri', 'knife');
// --
dictionaryWeaponCategory.set('weapon_studded_brokenfang_gloves', 'glove');
dictionaryWeaponCategory.set('weapon_studded_bloodhound_gloves', 'glove');
dictionaryWeaponCategory.set('weapon_sporty_gloves', 'glove');
dictionaryWeaponCategory.set('weapon_slick_gloves', 'glove');
dictionaryWeaponCategory.set('weapon_leather_handwraps', 'glove');
dictionaryWeaponCategory.set('weapon_motorcycle_gloves', 'glove');
dictionaryWeaponCategory.set('weapon_specialist_gloves', 'glove');
dictionaryWeaponCategory.set('weapon_studded_hydra_gloves', 'glove');


export const mappedContent = new Map();
mappedContent.set("pistol", { label: "Pistols", value: 'pistol', content: [] });
mappedContent.set("rifle", { label: "Rifles", value: 'rifle', content: [] });
mappedContent.set("sniper", { label: "Snipers", value: 'sniper', content: [] });
mappedContent.set("heavy", { label: "LMGs", value: 'heavy', content: [] });
mappedContent.set("smg", { label: "SMGs", value: 'smg', content: [] });
mappedContent.set("shotgun", { label: "Shotguns", value: 'shotgun', content: [] });
mappedContent.set("knife", { label: "Facas", value: 'knife', content: [] });
mappedContent.set("glove", { label: "Luvas", value: 'glove', content: [] });
mappedContent.set("agents", { label: "Agents", value: 'agents', content: [] });
mappedContent.set("music", { label: "Music Kits", value: 'music', content: [] });

export const mappedKnives = new Map();

mappedKnives.set('weapon_bayonet', { name: '★ Bayonet', content: [] });
mappedKnives.set('weapon_knife_css', { name: '★ Classic Knife', content: [] });
mappedKnives.set('weapon_knife_flip', { name: '★ Flip Knife', content: [] });
mappedKnives.set('weapon_knife_gut', { name: '★ Gut Knife', content: [] });
mappedKnives.set('weapon_knife_karambit', { name: '★ Karambit', content: [] });
mappedKnives.set('weapon_knife_m9_bayonet', { name: '★ M9 Bayonet', content: [] });
mappedKnives.set('weapon_knife_tactical', { name: '★ Huntsman Knife', content: [] });
mappedKnives.set('weapon_knife_falchion', { name: '★ Falchion Knife', content: [] });
mappedKnives.set('weapon_knife_survival_bowie', { name: '★ Bowie Knife', content: [] });
mappedKnives.set('weapon_knife_butterfly', { name: '★ Butterfly Knife', content: [] });
mappedKnives.set('weapon_knife_push', { name: '★ Shadow Daggers', content: [] });
mappedKnives.set('weapon_knife_cord', { name: '★ Paracord Knife', content: [] });
mappedKnives.set('weapon_knife_canis', { name: '★ Survival Knife', content: [] });
mappedKnives.set('weapon_knife_ursus', { name: '★ Ursus Knife', content: [] });
mappedKnives.set('weapon_knife_gypsy_jackknife', { name: '★ Navaja Knife', content: [] });
mappedKnives.set('weapon_knife_outdoor', { name: '★ Nomad Knife', content: [] });
mappedKnives.set('weapon_knife_stiletto', { name: '★ Stiletto Knife', content: [] });
mappedKnives.set('weapon_knife_widowmaker', { name: '★ Talon Knife', content: [] });
mappedKnives.set('weapon_knife_skeleton', { name: '★ Skeleton Knife', content: [] });
mappedKnives.set('weapon_knife_kukri', { name: '★ Kukri Knife', content: [] });

export const mappedGloves = new Map();
mappedGloves.set('weapon_studded_brokenfang_gloves', { name: '★ Broken Fang Gloves', content: [] });
mappedGloves.set('weapon_studded_bloodhound_gloves', { name: '★ Bloodhound Gloves', content: [] });
mappedGloves.set('weapon_sporty_gloves', { name: '★ Sport Gloves', content: [] });
mappedGloves.set('weapon_slick_gloves', { name: '★ Driver Gloves', content: [] });
mappedGloves.set('weapon_leather_handwraps', { name: '★ Hand Wraps', content: [] });
mappedGloves.set('weapon_motorcycle_gloves', { name: '★ Moto Gloves', content: [] });
mappedGloves.set('weapon_specialist_gloves', { name: '★ Specialist Gloves', content: [] });
mappedGloves.set('weapon_studded_hydra_gloves', { name: '★ Hydra Gloves', content: [] });

glovesJSON.forEach((element) => {
  let key;
  const splitted = element.paint_name.split(' | ');
  if (splitted[1]) {
    const gloveName = splitted[0];
    const paint_name = splitted[1];

    switch (gloveName) {
      case '★ Broken Fang Gloves': {
        key = 'weapon_studded_brokenfang_gloves';
        break;
      }
      case '★ Bloodhound Gloves': {
        key = 'weapon_studded_bloodhound_gloves';
        break;
      }
      case '★ Sport Gloves': {
        key = 'weapon_sporty_gloves';
        break;
      }
      case '★ Driver Gloves': {
        key = 'weapon_slick_gloves';
        break;
      }
      case '★ Hand Wraps': {
        key = 'weapon_leather_handwraps';
        break;
      }
      case '★ Moto Gloves': {
        key = 'weapon_motorcycle_gloves';
        break;
      }
      case '★ Specialist Gloves': {
        key = 'weapon_specialist_gloves';
        break;
      }
      case '★ Hydra Gloves': {
        key = 'weapon_studded_hydra_gloves';
        break;
      }
      default: {
        break;
      }
    }

    if (key) {
      const mg = mappedContent.get("glove");
      !mg.content.find((item) => item.value === key) &&
        (mg.content.push({ name: gloveName, value: key }));
      const existent = mappedGloves.get(key);
      existent.content.push({ ...element, paint_name, key });
    }
  }
});

export const mappedWeapons = new Map();
mappedWeapons.set('weapon_usp_silencer', { name: 'USP-S', team: Squad.CT, content: [] });
mappedWeapons.set('weapon_glock', { name: 'Glock-18', team: Squad.T, content: [] });
mappedWeapons.set('weapon_deagle', { name: 'Desert Eagle', team: Squad.NONE, content: [] });
mappedWeapons.set('weapon_elite', { name: 'Dual Berettas', team: Squad.NONE, content: [] });
mappedWeapons.set('weapon_fiveseven', { name: 'Five-SeveN', team: Squad.CT, content: [] });
mappedWeapons.set('weapon_ak47', { name: 'AK-47', team: Squad.T, content: [] });
mappedWeapons.set('weapon_aug', { name: 'AUG', team: Squad.CT, content: [] });
mappedWeapons.set('weapon_awp', { name: 'AWP', team: Squad.NONE, content: [] });
mappedWeapons.set('weapon_famas', { name: 'FAMAS', team: Squad.CT, content: [] });
mappedWeapons.set('weapon_g3sg1', { name: 'G3SG1', team: Squad.T, content: [] });
mappedWeapons.set('weapon_galilar', { name: 'Galil AR', team: Squad.T, content: [] });
mappedWeapons.set('weapon_m249', { name: 'M249', team: Squad.CT, content: [] });
mappedWeapons.set('weapon_m4a1', { name: 'M4A4', team: Squad.CT, content: [] });
mappedWeapons.set('weapon_mac10', { name: 'MAC-10', team: Squad.T, content: [] });
mappedWeapons.set('weapon_p90', { name: 'P90', team: Squad.NONE, content: [] });
mappedWeapons.set('weapon_mp5sd', { name: 'MP5-SD', team: Squad.NONE, content: [] });
mappedWeapons.set('weapon_ump45', { name: 'UMP-45', team: Squad.NONE, content: [] });
mappedWeapons.set('weapon_xm1014', { name: 'XM1014', team: Squad.NONE, content: [] });
mappedWeapons.set('weapon_bizon', { name: 'PP-Bizon', team: Squad.NONE, content: [] });
mappedWeapons.set('weapon_mag7', { name: 'MAG-7', team: Squad.CT, content: [] });
mappedWeapons.set('weapon_negev', { name: 'Negev', team: Squad.NONE, content: [] });
mappedWeapons.set('weapon_sawedoff', { name: 'Sawed-Off', team: Squad.T, content: [] });
mappedWeapons.set('weapon_tec9', { name: 'Tec-9', team: Squad.T, content: [] });
mappedWeapons.set('weapon_taser', { name: 'Zeus x27', team: Squad.NONE, content: [] });
mappedWeapons.set('weapon_hkp2000', { name: 'P2000', team: Squad.CT, content: [] });
mappedWeapons.set('weapon_mp7', { name: 'MP7', team: Squad.NONE, content: [] });
mappedWeapons.set('weapon_mp9', { name: 'MP9', team: Squad.CT, content: [] });
mappedWeapons.set('weapon_nova', { name: 'Nova', team: Squad.NONE, content: [] });
mappedWeapons.set('weapon_p250', { name: 'P250', team: Squad.NONE, content: [] });
mappedWeapons.set('weapon_scar20', { name: 'SCAR-20', team: Squad.CT, content: [] });
mappedWeapons.set('weapon_sg556', { name: 'SG 553', team: Squad.T, content: [] });
mappedWeapons.set('weapon_ssg08', { name: 'SSG 08', team: Squad.NONE, content: [] });
mappedWeapons.set('weapon_m4a1_silencer', { name: 'M4A1-S', team: Squad.CT, content: [] });

mappedWeapons.set('weapon_cz75a', { name: 'CZ75-Auto', team: Squad.NONE, content: [] });
mappedWeapons.set('weapon_revolver', { name: 'R8 Revolver', team: Squad.NONE, content: [] });

skinsJSON.map((element) => ({ ...element, paint_name: element.paint_name.replace('  |', ' |') })).forEach((element) => {
  const category = dictionaryWeaponCategory.get(element.weapon_name);
  const currentContent = mappedContent.get(category);
  const splitted = element.paint_name.split(' | ');
  // console.log(category)
  !currentContent.content.find((item) => item.name === splitted[0]) &&
    (currentContent.content.push({ name: splitted[0], value: element.weapon_name }));


  const isKnife = mappedKnives.has(element.weapon_name);
  let baseContent = [];
  if (isKnife) {
    baseContent = mappedKnives.get(element.weapon_name);
  } else {
    baseContent = mappedWeapons.get(element.weapon_name);
  }

  baseContent.content.push({ ...element, paint_name: splitted[1] });

})

export const mappedAgents = new Map();
mappedAgents.set(Squad.CT, agentsJSON.filter((element) => element.team === 3 && element.model !== 'null'));
mappedAgents.set(Squad.T, agentsJSON.filter((element) => element.team === 2 && element.model !== 'null'));

const mc = mappedContent.get('agents');
mc.content.push({ name: 'Counter-Terrorists', value: Squad.CT, content: mappedAgents.get(Squad.CT) }, { name: 'Terrorists', value: Squad.T, content: mappedAgents.get(Squad.T) });

export const mappedMusicKits = musicJSON.map((element) => {
  let name = element.name.replace('Music Kit | ', '');
  return { ...element, name, id: parseInt(element.id) }
});

const mmk = mappedContent.get("music");
mmk.content = mappedMusicKits;

export const mappedSquad = new Map();
mappedSquad.set(Squad.CT, 3);
mappedSquad.set(Squad.T, 2);

export const mappedMode = new Map();
mappedMode.set(Mode.Agent, 'Agentes');
mappedMode.set(Mode.Music, 'Music Kits');
mappedMode.set(Mode.Glove, 'Luvas');
mappedMode.set(Mode.Knife, 'Facas');
mappedMode.set(Mode.Skins, 'Skins');

// mappedContent.set("pistol", {label: "Pistols", value: 'pistol', content: []});
const specificOrders = new Map();
specificOrders.set('pistol', ['weapon_usp_silencer', 'weapon_glock', 'weapon_deagle', 'weapon_fiveseven', 'weapon_tec9', 'weapon_p250', 'weapon_elite', 'weapon_hkp2000', 'weapon_cz75a', 'weapon_revolver', 'weapon_taser',]);
specificOrders.set('rifle', ['weapon_ak47', 'weapon_m4a1_silencer', 'weapon_m4a1', 'weapon_galilar', 'weapon_famas', 'weapon_sg556', 'weapon_aug']);
specificOrders.set('sniper', ['weapon_awp', 'weapon_ssg08']);
specificOrders.set('smg', ['weapon_mac10', 'weapon_mp9', 'weapon_p90', 'weapon_mp7']);
specificOrders.set('heavy', []);
specificOrders.set('shotgun', []);

mappedContent.keys().forEach(key => {
  if (["knife", "glove", "agents", "music"].includes(key)) return;

  const current = mappedContent.get(key);
  const orders = specificOrders.get(key);

  current.content.sort((a, b) => {
    const indexA = orders.indexOf(a.value);
    const indexB = orders.indexOf(b.value);

    // Handle missing elements by assigning a large index for them
    const adjustedIndexA = indexA === -1 ? Number.MAX_SAFE_INTEGER : indexA;
    const adjustedIndexB = indexB === -1 ? Number.MAX_SAFE_INTEGER : indexB;

    return adjustedIndexA - adjustedIndexB;
  });
})
