import React, { useCallback, useEffect, useState } from 'react';
import { useDownload } from 'hooks';

import { BsDownload } from 'react-icons/bs';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

export default function DownloadComponent({uuid}) {
  const {
    downloaded,
    showProgressBar,
    preExecDownloadV2
  } = useDownload();

  const [waiting, setWaiting] = useState(true);
  const [matchAvailable, setMatchAvailable] = useState(true);
  const [matchinfo, setMatchinfo] = useState({ path: '', size: 0, downloaded: 0, uuid: '' })

  const getInfos = useCallback(async () => {
    try {
      const response = await endpoint.get(`/proxy/demo/${uuid}/info`);
      setMatchinfo(response)
      setMatchAvailable(true);
    } catch (e){
      if(e.status !== 200){
        setMatchAvailable(false);
      }
      console.error('Error fetch match info', e.status);
    } finally {
      setWaiting(false);
    }
  }, [])

  const handleDownload = useCallback(async () => {
    // setWaiting((state) => !state);
    const _prev = {...matchinfo};
    preExecDownloadV2(_prev);
    setMatchinfo((state) => ({...state, downloaded: state.downloaded+1}));
      // execDownload();
  }, [matchinfo]);

  useEffect(() => {
    getInfos();
  }, []);

  return matchAvailable ? (
    <div className="flex flex-row items-center">
      {!waiting ? (
        !showProgressBar ? (
          <div>
            <button
              className="flex flex-row items-center justify-center py-1 px-3 border-2 border-emerald-400 bg-emerald-200/10 rounded text-emerald-400 hover:bg-emerald-200/30 hover:text-white"
              onClick={handleDownload}
              style={{ transition: 'all 0.35s' }}
            >
              Download .dem <BsDownload className="ml-2" />
            </button>
            <p className='italic text-xs text-center text-slate-300'>Downloaded {matchinfo.downloaded} times</p>
          </div>
        ) : (
          <div className="w-52 flex flex-col items-end italic text-emerald-300">
            <ProgressBar
              id="file"
              value={downloaded}
              max="100"
              className="bg-white/25 rounded"
            >
              {downloaded}
            </ProgressBar>
            <span>
              <strong>{downloaded}% concluído</strong>
            </span>
          </div>
        )
      ) : (
        <div className="flex flex-row items-center gap-x-3 ">
          <span className="italic text-emerald-200 ">
            Aguarde, preparando download
          </span>
          <span className="animate-spin text-emerald-200">
            <AiOutlineLoading3Quarters className="h-5 w-5 text-emerald-200" />
          </span>
        </div>
      )}
    </div>
  ) : null;
}

import styled from 'styled-components';
import endpoint from 'service/endpoint';
const ProgressBar = styled.progress`
  &[value] {
    margin: 0 auto;
    -webkit-appearance: none;
    appearance: none;

    width: 100%;
    height: 20px;
    position: relative;

    &::-webkit-progress-bar {
      background-color: transparent;
      border-radius: 2px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
    }

    &::-webkit-progress-value {
      /* background: #fce205; */
      box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.5) inset;
      background-image: -webkit-linear-gradient(
          45deg,
          transparent 33%,
          rgba(0, 0, 0, 0.1) 33%,
          rgba(0, 0, 0, 0.1) 66%,
          transparent 66%
        ),
        -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.25)),
        -webkit-linear-gradient(left, var(--base-green), var(--secondary-green));

      border-radius: 3px;
      background-size: 35px 20px, 100% 100%, 100% 100%;
    }
  }
`;
