import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';

import JsFileDownloader from 'js-file-downloader';

const DownloadContext = createContext({});

function DownloadProvider({ children }) {
  const [downloaded, setDownloaded] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [preActionReference, setPreActionReference] = useState(false);
  const [path, setPath] = useState('');
  const [contentType, setContentType] = useState('');
  const [contentInfo, setContentInfo] = useState(() => ({size: 0, path:''}));
  const [file, setFile] = useState({});
  const [eventDetails, setEventDetails] = useState({});
  const [preActionStatus, setPreactionStatus] = useState(false);

  function process_loading(event) {

    if (!event.lengthComputable) return;
    const downloadingPercentage = parseFloat(
      ((event.loaded / event.total) * 100).toFixed(2),
    );
    setDownloaded(downloadingPercentage);
    // what to do ...
  }
  function process_loadingV2(event) {
    if (contentInfo.size === 0) return;
    const downloadingPercentage = parseFloat(
      ((event.loaded / contentInfo.size) * 100).toFixed(2),
    );
    setDownloaded(downloadingPercentage);
    // what to do ...
  }

  const refreshPreActionStatus = useCallback((status) => {
    setPreactionStatus(status);
  }, []);

  const execDownload = useCallback(() => {
    const { filename, extension, contentType } = file;
    let filepath = `${process.env.REACT_APP_CDN_DEMOS_DIR}`;
    let filetype = 'application/octet-stream';
    if (path) {
      filepath = path;
    }

    if (contentType) {
      filetype = contentType;
    }

    // const fUrl = `${filepath}/${filename}.${extension}`;
    const fUrl = `${filepath}/${filename}.${extension}`;
    setDownloaded(0);
    setShowProgressBar((state) => !state);

    const returned = new JsFileDownloader({
      url: fUrl,
      autoStart: false,
      forceDesktopMode: true,
      timeout: 360000,
      filename: `${filename}.${extension}`,
      // nameCallback() {
      //   return `${filename}.${extension}`;
      // },
      contentType: filetype,
      process: process_loading,
    });

    returned
      .start()
      .then(() => {})
      .catch((err) => console.error(err))
      .finally(() => {
        setShowProgressBar((state) => !state);
        setPreactionStatus(false);
      });
  }, [contentType, path, file]);

  const execDownloadV2 = useCallback(({_uuid}) => {
    let filepath = `${process.env.REACT_APP_BNE_ENDPOINT}`;
    let filetype = 'application/octet-stream';

    const fUrl = `${filepath}/proxy/demo/${_uuid}`;
    // setDownloaded(0);
    // setShowProgressBar((state) => !state);

    const returned = new JsFileDownloader({
      url: fUrl,
      autoStart: false,
      forceDesktopMode: true,
      timeout: 360000,
      filename: `${contentInfo.path}`,
      contentType: filetype,
      process: process_loadingV2,
    });

    returned
      .start()
      .then(() => {})
      .catch((err) => console.error(err))
      .finally(() => {
        setTimeout(() => {
          setShowProgressBar((state) => !state);
          setContentInfo({size: 0, path:''})
        }, 3_500)
        // setPreactionStatus(false);
      });
  }, [contentInfo])

  const preExecDownloadV2 = useCallback(async ({ path, size, downloaded, uuid }) => {
    setDownloaded(0);
    setContentInfo({ path, size, downloaded, uuid });
    setShowProgressBar((state) => !state);
  }, []);

  useEffect(() => {
    if(contentInfo.size > 0){
      execDownloadV2({_uuid: contentInfo.uuid});
    }
  }, [contentInfo]);
  // useEffect(() => {
  //   console.log('downloaded',downloaded)
  // }, [downloaded])

  const downloadConfig = useCallback((ev) => {
    setPreActionReference(ev.detail.preaction);
    setPath(ev.detail.path);
    setContentType(ev.detail.contentType);
    setFile(ev.detail.file);
    setEventDetails(ev.detail.eventDetails);
  }, []);

  useEffect(() => {
    if (preActionStatus === true) {
      execDownload();
    }
  }, [preActionStatus]);

  return (
    <DownloadContext.Provider
      value={{
        execDownload,
        preExecDownloadV2,
        execDownloadV2,
        downloadConfig,
        preActionReference,
        preActionStatus,
        refreshPreActionStatus,
        downloaded,
        showProgressBar,
        eventDetails,
        file,
      }}
    >
      {children}
    </DownloadContext.Provider>
  );
}

function useDownload() {
  const context = useContext(DownloadContext);

  if (!context) {
    throw new Error('useDownload must be used within an AuthProvider');
  }

  return context;
}

export { useDownload, DownloadProvider };
